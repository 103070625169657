:root {
  --black: #1c1c1c;
  --grey-black-45: rgba(0, 0, 0, 0.45);
  --white: #ffffff;
  --pale-gray: #f9fafb;
  --light-gray: #d6d6d6;
  --lekto-primary: #400d40;
  --lekto-primary-hover: #6b2669;
  --lekto-green: #63da38;
  --lekto-green-dark: #6dd400;
  --lekto-green-light: #9cef69;
  --lekto-blue: #1890ff;
  --neutral-color-low-main: #000;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: #fafafa;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  color: var(--black);
}

.white-background {
  background-color: #fff;
}

.content {
  padding: 24px 24px;
}

/* Container */
.container {
  max-width: 1056px;
  padding-right: 16px;
  padding-left: 16px;
  margin: auto;
}

/* Typography */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.center {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.text-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

td.td-actions {
  width: 150px;
}

.primary-color {
  color: var(--lekto-primary);
}

.ant-menu-vertical.ant-menu-sub {
  min-width: 205px;
}

.lekto-label {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 16px !important;
  color: var(--grey-black-45);
}

.ant-modal-header {
  padding-top: 24px;
  padding-bottom: 0px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  font-variant: "Inter";
  font-weight: bold;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 8px;
}

.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.21;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 16px 0px;
}

.ant-table-thead > tr > th {
  font-weight: 500;
  text-align: left;
  background: #f5f5f5;
  border-bottom: 1px solid #f0f0f0;
}

.ant-menu {
  font-variant: "Inter";
  font-size: 13px;
  border-bottom: 0px;
}

.ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.35);
}

.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.45);
}

.ant-modal-title {
  font-variant: "Inter";
  font-size: 21px;
}

.ant-modal-content {
  border-radius: 8px;

  .result-modal {
    padding: 12px;
    text-align: center;

    .modal-badges {
      margin-bottom: 15px;
    }

    p {
      font-size: 20px;
      font-weight: 600;
      color: var(--black);
    }

    button {
      margin-bottom: 15px;
    }
  }
}

.ant-popover-content {
  border-radius: 8px;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-modal-header {
  padding: var(--modal-padding, 16px)
    var(--modal-padding-content-horizontal-lg, 24px) var(--modal-margin-xs, 8px)
    var(--modal-padding-content-horizontal-lg, 24px);
}

.ant-modal-body {
  padding: var(--margin, 16px) var(--modal-padding-content-horizontal-lg, 24px)
    var(--modal-margin, 16px) var(--modal-padding-content-horizontal-lg, 24px);
}

.ant-modal-footer {
  border-top: none;

  padding: 0px var(--modal-padding-content-horizontal-lg, 24px)
    var(--modal-padding-content-horizontal-lg, 24px)
    var(--modal-padding-content-horizontal-lg, 24px);
}

.ant-btn {
  border-radius: 6px;
}

.ant-tag {
  border-radius: 4px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}

.ant-input-affix-wrapper {
  border-radius: 6px;
}

.ant-input {
  border-radius: 6px;
}

.ant-input-prefix,
.ant-input-suffix {
  padding-left: 8px;
  border-left: 1px solid #00000026;
}

.subtitle {
  color: var(--input-color-text, rgba(0, 0, 0, 0.88));

  /* Light/Base/Base Normal */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.subtitle.large {
  font-size: 120%;
}

.ant-menu {
  background-color: transparent !important;
}

.ant-menu-vertical {
  background-color: #fff !important;
}

.ant-picker-range {
  border-radius: 6px;
}

.avatar {
  button {
    width: 95px;
    height: 95px;
    background-color: #d8d8d8;
    color: #a1a1a1;
    border-width: 2px;
    border-color: #a1a1a1;

    i {
      font-size: 35px;
      margin-top: 8px;
    }
  }

  a {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.45);

    :hover {
      text-decoration: underline;
    }
  }

  a:hover {
    text-decoration: underline;
  }

  nz-avatar {
    cursor: pointer;
    margin: 0px !important;
    background-color: #d8d8d8;
  }
}

.ant-badge-count {
  padding-top: 1px;
}

nz-avatar.avatar {
  background-color: #9b9b9b !important;
}

nz-tag.shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}

.ant-page-header-heading-title {
  font-size: 230% !important;
  padding: 5px;
}

nz-select {
  width: 100%;
}

.page-bf {
  page-break-before: always;
  break-before: always;
}

.page-bav {
  page-break-inside: avoid;
  break-inside: avoid;
}

// Moment status colors
.style-agen-moment {
  color: #7c8189;
}

.style-pend-moment {
  color: #0095ff;
}

.style-aupl-moment {
  color: #8c22bc;
}

.style-aupe-moment {
  color: #d44333;
}

.style-avpe-moment {
  color: #ff8a00;
}

.style-fina-moment {
  color: #29cc5f;
}

.style-canc-moment {
  color: #ff0000;
}

.w-100 {
  width: 100%;
}

.ellipsize {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

th.ant-table-cell {
  font-weight: 700;
  font-size: 15px;
}

.ant-form-item-label {
  label {
    color: var(--input-color-text, rgba(0, 0, 0, 0.88));

    /* Light/Base/Base Normal */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}

.ant-color-picker-trigger {
  justify-content: flex-start;
  border-radius: 6px;
}

// Global Quill styles
quill-editor {
  min-width: 100%;
  border-radius: 8px;

  .ql-editor {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    /* 150% */
    p {
      font-size: 16px;
    }

    li {
      font-size: 15px;
    }

    ul {
      font-size: 15px;
    }
  }

  .ql-container {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .ql-blank {
      color: var(--input-color-text-placeholder, rgba(0, 0, 0, 0.25));

      /* Light/LG/LG Normal */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .ql-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

quill-editor.ng-touched.ng-dirty.ng-invalid {
  .ql-container {
    border: 1px solid red;
  }

  .ql-toolbar {
    border: 1px solid red;
    border-bottom: 0px;
  }
}

.nz-custom-tag {
  margin-top: 5px;
}

.nz-tag-custom {
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  gap: 4px;
  max-width: 100%;
}
